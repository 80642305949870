import { graphql, useStaticQuery } from 'gatsby';
import { AllDatoCmsHouse, House } from '@interfaces/house';

const useHouses = (): House[] => {
  const {
    allDatoCmsHouse: { nodes },
  }: AllDatoCmsHouse = useStaticQuery(query);

  return nodes as House[];
};

const query = graphql`
  {
    allDatoCmsHouse(sort: { fields: houseNumber, order: ASC }) {
      nodes {
        houseNumber
        availability
        area
        parcelArea
        price
        deliveryDate(formatString: "DD MMMM YYYY", locale: "PL")
        garage
        firstFloor {
          roomNumber
          name
          area
        }
        secondFloor {
          roomNumber
          name
          area
        }
      }
    }
  }
`;

export default useHouses;
