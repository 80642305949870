import React, { FC } from 'react';
import styled from 'styled-components';
import { Icons } from '@config/icons';
import Paragraph from '@components/atoms/Paragraph/Paragraph';
import Icon from '@iconify/react';
import { useIcon } from '@hooks/useIcon';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 272px;
  margin: auto;
  background: ${({ theme }) => theme.background};
  border-radius: 40px;
  box-shadow: 0 20px 99px #0000001a;
  align-items: center;
  justify-content: center;
  padding: 20px 70px;
  min-height: 220px;

  @media (min-width: 1025px) {
    width: 370px;
    max-width: unset;
    height: 370px;
  }

  @media print {
    break-inside: avoid;
    box-shadow: unset;
    padding: 0;
    height: unset;
    min-height: unset;
    margin-bottom: 40px;
  }
`;

const StyledIconWrapper = styled.div`
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.background};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3.5rem;
  margin-bottom: 15px;

  @media (min-width: 1025px) {
    width: 115px;
    height: 115px;
    font-size: 5rem;
    margin-bottom: 40px;
  }

  @media print {
    display: none;
  }
`;

const StyledHeadline = styled.h3`
  font-size: ${({ theme }) => theme.bodyS};
  font-weight: ${({ theme }) => theme.regular};
  margin: 0;

  @media (min-width: 1025px) {
    font-size: ${({ theme }) => theme.bodyXXL};
  }
`;

const StyledParagraph = styled(Paragraph)`
  text-align: center;
  color: ${({ theme }) => theme.gray400};

  @media (min-width: 1025px) {
    margin-top: 30px;
    font-size: ${({ theme }) => theme.bodyM};
  }
`;

const ContactBlock: FC<Props> = ({
  icon,
  headline,
  children,
  className,
  ...props
}) => (
  <StyledWrapper className={className} {...props}>
    <StyledIconWrapper>
      <Icon icon={useIcon(icon)} />
    </StyledIconWrapper>
    <StyledHeadline>{headline}</StyledHeadline>
    <StyledParagraph>{children}</StyledParagraph>
  </StyledWrapper>
);

interface Props {
  icon: Icons;
  headline: string;
  className?: string;
}

export default ContactBlock;
