import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Icons } from '@config/icons';
import { useButtonEffects } from '@hooks/useButtonEffects';
import { useIcon } from '@hooks/useIcon';
import Icon from '@iconify/react';
import { rippleStyles } from '@theme/ripple';

interface StyledProps {
  $isPressed?: boolean;
  $isIcon?: boolean;
  $isLeft?: boolean;
  $secondary?: boolean;
  $iconSize?: string;
}

const StyledButton = styled.button<StyledProps>`
  display: flex;
  background: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.background};
  border: 0;
  padding: 15px 25px;
  border-radius: 99999rem;
  font-weight: ${({ theme }) => theme.bold};
  outline: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  user-select: none;
  text-decoration: none;

  :hover {
    background: ${({ theme }) => theme.primary100};
  }

  @media (min-width: 1025px) {
    padding: 25px 40px;
    font-size: ${({ theme }) => theme.bodyL}!important;
  }

  ${({ $secondary, theme }) =>
    $secondary &&
    css`
      background: ${theme.gray200};

      :hover {
        background: ${({ theme }) => theme.gray300};
      }
    `}

  ${({ $isPressed }) =>
    $isPressed &&
    css`
      transform: scale(0.95);
    `}

  ${({ $isIcon }) =>
    $isIcon &&
    css`
      padding: 5px 5px 5px 25px;

      @media (min-width: 1025px) {
        padding: 8px 8px 8px 40px;
      }
    `}

  ${({ $isIcon, $isLeft }) =>
    $isIcon &&
    $isLeft &&
    css`
      padding: 5px 25px 5px 5px;

      @media (min-width: 1025px) {
        padding: 8px 40px 8px 8px;
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      :hover {
        background: ${({ theme }) => theme.primary};
      }
    `};

  ${rippleStyles}
`;

const StyledIconWrapper = styled.div<StyledProps>`
  width: 40px;
  height: 40px;
  background: ${({ theme }) => theme.background};
  border-radius: 50%;
  margin-left: auto;
  color: ${({ theme }) => theme.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;

  ${({ $isLeft }) =>
    $isLeft &&
    css`
      order: -1;
      margin-left: unset;
      margin-right: auto;
    `}

  ${({ $iconSize }) =>
    $iconSize &&
    css`
      font-size: ${$iconSize}!important;
    `}

  ${({ $secondary, theme }) =>
    $secondary &&
    css`
      color: ${theme.gray200};
    `};

  @media (min-width: 1025px) {
    width: 60px;
    height: 60px;
    font-size: 2.2rem;
  }
`;

const StyledTextWrapper = styled.div<StyledProps>`
  ${({ $isIcon }) =>
    $isIcon &&
    css`
      margin-right: 20px;
    `}

  ${({ $isLeft, $isIcon }) =>
    $isLeft &&
    $isIcon &&
    css`
      margin-left: 20px;
      margin-right: unset;
    `}
`;

const Button: FC<Props> = ({
  children,
  onClick,
  className,
  disabled,
  icon,
  secondary,
  iconPosition,
  iconSize,
  as,
  ...props
}) => {
  const {
    isPressed,
    onMouseUpAndTouchEnd,
    onTouchStart,
    onMouseDown,
  } = useButtonEffects();
  const iconifyIcon = useIcon(icon);

  const isLeft = iconPosition === 'left';
  return (
    <StyledButton
      className={className}
      onClick={!disabled ? onClick : undefined}
      onMouseDown={!disabled ? onMouseDown : undefined}
      onMouseUp={!disabled ? onMouseUpAndTouchEnd : undefined}
      onTouchStart={!disabled ? onTouchStart : undefined}
      disabled={disabled}
      $isPressed={isPressed}
      $isIcon={!!icon}
      $secondary={secondary}
      $isLeft={isLeft}
      as={as}
      {...props}
    >
      <StyledTextWrapper $isLeft={isLeft} $isIcon={!!icon}>
        {children}
      </StyledTextWrapper>
      {icon && (
        <StyledIconWrapper
          $isLeft={isLeft}
          $iconSize={iconSize}
          $secondary={secondary}
        >
          <Icon icon={iconifyIcon} />
        </StyledIconWrapper>
      )}
    </StyledButton>
  );
};

interface Props {
  onClick?: () => void;
  ariaLabel?: string;
  icon?: Icons;
  iconSize?: string;
  iconPosition?: 'left' | 'right';
  secondary?: boolean;
  className?: string;
  disabled?: boolean;
  as?: any;
}

export default Button;
