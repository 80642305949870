import { useContext, useEffect, useRef, useState } from 'react';
import { navigate } from '@reach/router';
import Context from '@context';
import { Availability } from '@enums/availability';
import useHouses from '@hooks/useHouses';

interface Arguments {
  isOnlyAvailable: boolean;
}

export const UseInteractiveVisualizationLogic = ({
  isOnlyAvailable,
}: Arguments) => {
  const [currentHouse, setCurrentHouse] = useState<number>(1);
  const [pathOffsets, setPathOffsets] = useState<
    { right: number; bottom: number }[]
  >();
  const [wrapperOffset, setWrapperOffset] = useState<{
    right: number;
    bottom: number;
  }>();
  const [isDropdownActive, setDropdownActive] = useState<boolean>(false);
  const [delayHandler, setDelayHandler] = useState(null);
  const houses = useHouses();

  const { width } = useContext(Context);

  const paths = useRef<SVGPathElement[]>([]);
  const wrapper = useRef<HTMLDivElement>();

  const getClassName = (availability: Availability) => {
    switch (availability) {
      case Availability.Available:
        return 'available';
      case Availability.Reserved:
        return 'reserved';
      case Availability.Unavailable:
        return 'unavailable';
    }
  };

  const shouldDisplay = (availability: Availability) => {
    if (!isOnlyAvailable) return true;

    return (
      availability !== Availability.Unavailable &&
      availability !== Availability.Reserved
    );
  };

  const getPathProps = (index: number) => {
    const className = getClassName(houses[index].availability);
    const ariaCurrent = currentHouse === index;
    const ariaLabel = `Zobacz szczegóły domu ${index + 1}`;

    const onMouseEnter = () => {
      setCurrentHouse(index);
      setDropdownActive(true);

      if (delayHandler) {
        clearTimeout(delayHandler);
        setDelayHandler(null);
      }
    };

    const onMouseLeave = ({
      relatedTarget,
    }: React.MouseEvent<SVGPathElement, MouseEvent>) => {
      if (
        (relatedTarget instanceof HTMLElement ||
          relatedTarget instanceof SVGElement) &&
        (relatedTarget.classList.contains('dropdown') ||
          relatedTarget.nodeName === 'path')
      ) {
        return;
      }

      setDelayHandler(
        setTimeout(() => {
          setDropdownActive(false);
        }, 300)
      );
    };
    const onClick = () => navigate(`/dom${index + 1}`);
    const onTouchEnd = () => navigate(`/dom${index + 1}`);

    return {
      className,
      onMouseEnter,
      onMouseLeave,
      $isActive: isDropdownActive && currentHouse === index,
      onClick,
      // onTouchEnd,
      ref: (path: SVGPathElement) => (paths.current[index] = path),
      'aria-current': ariaCurrent,
      'aria-label': ariaLabel,
    };
  };

  useEffect(() => {
    if (wrapper) {
      const { right, bottom } = wrapper.current.getBoundingClientRect();
      setWrapperOffset({ right, bottom });
    }
  }, [wrapper, width]);

  useEffect(() => {
    if (paths.current.length === 6 && wrapperOffset?.right) {
      let offsets: { right: number; bottom: number }[] = [];

      paths.current.forEach((path) => {
        const { right, bottom } = path.getBoundingClientRect();

        offsets.push({
          right: wrapperOffset.right - right,
          bottom: wrapperOffset.bottom - bottom,
        });
      });

      setPathOffsets(offsets);
    }

    return () => {
      setPathOffsets([]);
    };
  }, [paths, wrapperOffset, width]);

  return {
    pathOffsets,
    isDropdownActive,
    setDropdownActive,
    shouldDisplay,
    getPathProps,
    wrapper,
    currentHouse,
    getClassName,
    width,
    delayHandler,
    setDelayHandler,
    houses,
  };
};
