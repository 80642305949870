import React, { FC } from 'react';
import styled from 'styled-components';
import { useIcon } from '@hooks/useIcon';
import Icon from '@iconify/react';

const StyledWrapper = styled.label`
  display: flex;
  font-size: ${({ theme }) => theme.bodyXS};
  align-items: center;
  user-select: none;
  cursor: pointer;

  @media (min-width: 1025px) {
    font-size: ${({ theme }) => theme.bodyL};
  }
`;

const StyledCheckboxWrapper = styled.div`
  position: relative;
  width: 18px;
  height: 18px;
  margin-right: 8px;

  @media (min-width: 1025px) {
    width: 20px;
    height: 20px;
  }
`;

const StyledCheckbox = styled.input`
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  margin: 0;
`;

const StyledCheckboxImitation = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.foreground};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.background};
  border-radius: 3px;

  ${StyledCheckbox}:checked + & {
    background: ${({ theme }) => theme.foreground};
  }
`;

const Checkbox: FC<Props> = ({ children, onChange, checked }) => {
  const icon = useIcon('check');

  return (
    <StyledWrapper>
      <StyledCheckboxWrapper>
        <StyledCheckbox type="checkbox" onChange={onChange} checked={checked} />
        <StyledCheckboxImitation>
          <Icon icon={icon} />
        </StyledCheckboxImitation>
      </StyledCheckboxWrapper>
      {children}
    </StyledWrapper>
  );
};

interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
}

export default Checkbox;
