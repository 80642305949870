import React, { FC } from 'react';
import styled from 'styled-components';

interface StyledProps {
  $color: string;
}

const StyledWrapper = styled.div`
  font-size: ${({ theme }) => theme.bodyXS};
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  @media (min-width: 1025px) {
    font-size: ${({ theme }) => theme.bodyL};
  }
`;

const StyledDot = styled.div<StyledProps>`
  width: 15px;
  height: 15px;
  background: ${({ theme, $color }) => (theme as any)[$color]};
  border-radius: 50%;
  margin-right: 5px;

  @media (min-width: 1025px) {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
`;

const Legend: FC<Props> = ({ children, color, className }) => (
  <StyledWrapper className={className}>
    <StyledDot $color={color} />
    {children}
  </StyledWrapper>
);

interface Props {
  color: 'available' | 'reserved' | 'unavailable';
  className?: string;
}

export default Legend;
