import React, { Dispatch, FC, SetStateAction } from 'react';
import styled, { css } from 'styled-components';
import { Availability } from '@enums/availability';
import { Link } from 'gatsby';
import { formatPrice } from '@utils/formatPrice';

interface StyledProps {
  $isActive?: boolean;
  $right?: number;
  $bottom?: number;
  $availability?: Availability;
}

const StyledWrapper = styled.div<StyledProps>`
  position: absolute;
  right: 0;
  bottom: 0;
  transform: ${({ $right, $bottom }) =>
    `translate(calc(-${$right}px + 50%), calc(-${$bottom}px + 85%))`};
  opacity: 0;
  background: ${({ theme }) => theme.background};
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
  pointer-events: none;
  border-radius: 36px;
  padding: 20px 20px 30px;
  display: flex;
  flex-direction: column;
  max-width: 250px;

  ${({ $isActive }) =>
    $isActive &&
    css`
      opacity: 1;
      pointer-events: all;
    `}
`;

const StyledHouseNumber = styled.span`
  font-size: ${({ theme }) => theme.bodyXS};
  text-transform: uppercase;
  margin-bottom: 7px;
`;

const StyledAvailability = styled.h3<StyledProps>`
  font-size: ${({ theme }) => theme.bodyXXL};
  font-weight: ${({ theme }) => theme.bold};
  color: ${({ theme, $availability }) =>
    $availability === Availability.Available
      ? theme.available
      : $availability === Availability.Unavailable
      ? theme.unavailable
      : theme.reserved};
  margin: 0 0 7px;
`;

const StyledItem = styled.p`
  margin: 0 0 4px;
  font-size: ${({ theme }) => theme.bodyXS};

  span {
    font-weight: ${({ theme }) => theme.bold};
  }

  :nth-of-type(3) {
    line-height: 1.4;
  }
`;

const StyledPrePrice = styled.span`
  margin: 11px auto 5px auto;
  font-size: ${({ theme }) => theme.bodyXS};
`;

const StyledPrice = styled.p`
  font-size: ${({ theme }) => theme.headingXXS};
  font-weight: ${({ theme }) => theme.bold};
  color: ${({ theme }) => theme.primary};
  margin: 0;
  text-align: center;
`;

const StyledLink = styled(Link)`
  margin-top: 15px;
  color: ${({ theme }) => theme.foreground};
  text-decoration: none;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 3000px;
  padding: 10px 0;
  text-align: center;
  font-size: ${({ theme }) => theme.bodyXS};
  transition: color 0.2s ease-in-out, background 0.2s ease-in-out;

  :hover {
    background: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.background};
  }
`;

const Dropdown: FC<Props> = ({
  houseNumber,
  isActive,
  right,
  bottom,
  availability,
  area,
  parcelArea,
  deliveryDate,
  price,
  setIsActive,
  delayHandler,
  setDelayHandler,
  garage,
}) => {
  const handleMouseOver = () => {
    setIsActive(true);

    if (delayHandler) {
      clearTimeout(delayHandler);
      setDelayHandler(null);
    }
  };
  const handleMouseLeave = () => setIsActive(false);

  return (
    <StyledWrapper
      $isActive={isActive}
      $right={right}
      $bottom={bottom}
      onMouseOver={handleMouseOver as any}
      onMouseLeave={handleMouseLeave as any}
      className="dropdown"
    >
      <StyledHouseNumber>Dom nr. {houseNumber}</StyledHouseNumber>
      <StyledAvailability $availability={availability}>
        {availability === Availability.Available
          ? 'Dostępny'
          : availability === Availability.Unavailable
          ? 'Niedostępny'
          : 'Zarezerwowany'}
      </StyledAvailability>
      <StyledItem>
        Powierzchnia: <span>{area.toString().replace('.', ',')} m2</span>
      </StyledItem>
      <StyledItem>
        Powierzchnia działki:{' '}
        <span>{parcelArea.toString().replace('.', ',')} m2</span>
      </StyledItem>
      <StyledItem>
        Garaż: <span>{garage}</span>
      </StyledItem>
      <StyledItem>
        Termin oddania: <span>{deliveryDate}</span>
      </StyledItem>
      <StyledPrePrice>Cena:</StyledPrePrice>
      <StyledPrice>{formatPrice(price)}</StyledPrice>
      <StyledLink to={`/dom${houseNumber}`}>Zobacz więcej</StyledLink>
    </StyledWrapper>
  );
};

interface Props {
  isActive: boolean;
  setIsActive: Dispatch<SetStateAction<boolean>>;
  right: number;
  bottom: number;
  houseNumber: number;
  availability: Availability;
  area: number;
  parcelArea: number;
  deliveryDate: string;
  price: number;
  delayHandler: any;
  setDelayHandler: (newState: any) => void;
  garage: string;
}

export default Dropdown;
