import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { UseInteractiveVisualizationLogic } from '@components/molecules/InteractiveVisualization/useInteractiveVisualizationLogic';
import Dropdown from '@components/molecules/InteractiveVisualization/Dropdown/Dropdown';

interface StyledProps {
  $right?: number;
  $bottom?: number;
  $isActive?: boolean;
}

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;

  svg {
    width: 100%;
    height: 100%;
  }

  circle {
    pointer-events: none;

    &.available {
      fill: ${({ theme }) => rgba(theme.available, 0.8)};
    }

    &.reserved {
      fill: ${({ theme }) => rgba(theme.reserved, 0.8)};
    }

    &.unavailable {
      fill: ${({ theme }) => rgba(theme.unavailable, 0.8)};
    }
  }

  text {
    pointer-events: none;
  }
`;

const StyledPath = styled.path<StyledProps>`
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;

  &.available {
    fill: ${({ theme }) => theme.available};
  }

  &.reserved {
    fill: ${({ theme }) => theme.reserved};
  }

  &.unavailable {
    fill: ${({ theme }) => theme.unavailable};
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      opacity: 0.4;
    `}
`;

const MappedVector: FC<Props> = ({ className, isOnlyAvailable }) => {
  const {
    wrapper,
    pathOffsets,
    isDropdownActive,
    shouldDisplay,
    getPathProps,
    currentHouse,
    getClassName,
    setDropdownActive,
    width,
    delayHandler,
    setDelayHandler,
    houses,
  } = UseInteractiveVisualizationLogic({ isOnlyAvailable });

  const circleSize = width >= 1025 ? 25 : 40;
  const fontSize = width >= 1025 ? 22 : 40;

  return (
    <StyledWrapper ref={wrapper} className={className}>
      {width >= 1025 && (
        <Dropdown
          right={pathOffsets?.[currentHouse]?.right}
          bottom={pathOffsets?.[currentHouse]?.bottom}
          isActive={isDropdownActive}
          setIsActive={setDropdownActive}
          delayHandler={delayHandler}
          setDelayHandler={setDelayHandler as any}
          {...houses[currentHouse]}
        />
      )}

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="2560"
        height="1440"
        viewBox="0 0 2560 1440"
      >
        {shouldDisplay(houses[0].availability) && (
          <>
            <StyledPath
              d="M462.2 582.88l89.358-97.542 6.35.099 71.836 34.59 168.9-40.794-.37-11.48 2.303-2.83 2.868 2.947.241-3.45 3.79 3.835.41 8.95 26.206-7.42 82.629 143.18 9.999 5.66s-.648 6.64-6.954 7.148c-.272 6.076 11.754 173.2 11.754 173.2l-17.155 6.494 11.766 11.514-82.483 34.81-13.895-11.787-51.625 17.72 5.553 4.633.56 6.737-39.493 14.78-10.482-11.823-106.51 34.818-131.4-118.98z"
              {...getPathProps(0)}
            />
            <circle
              cx="-813.38"
              cy="-584.03"
              r={circleSize}
              className={getClassName(houses[0].availability)}
              transform="scale(-1)"
            />
            <text
              x={width >= 1025 ? '808.213' : '802.000'}
              y={width >= 1025 ? '590.646' : '596'}
              fontFamily="Roboto"
              fontSize={fontSize}
              style={{ lineHeight: '1' }}
            >
              <tspan
                x={width >= 1025 ? '808.213' : '802.000'}
                y={width >= 1025 ? '590.646' : '596'}
                fill="#fff"
                fontFamily="Roboto"
                fontWeight="bold"
              >
                {houses[0].houseNumber}
              </tspan>
            </text>
          </>
        )}

        {shouldDisplay(houses[1].availability) && (
          <>
            <StyledPath
              d="M1341.758 635.616l-.203-157.664 2.697.065 4.1-1.238-.112-4.77-6.3-1.391-83.736-127.161-28.667 6.532-.262-4.714-1.742-1.684-.953.051-1.211 1.946-1.406.079-.236-1.878-3.024.189.666 8.121-152.195 35.44-21.796-11.034-33.69-20.646-2.508.361-75.907 86.158 13.417 188.256 5.844 3.36 2.096-.312 149.124 88.44 81.109-27.316 11.556 7.606 31.757-11.459-.736-7.273-4.672-2.944 33.526-13.741 19.756 10.344 68.285-28.082-15.305-9.456z"
              {...getPathProps(1)}
            />
            <g transform="translate(446.18 -143.53)">
              <circle
                cx="-813.38"
                cy="-584.03"
                r={circleSize}
                className={getClassName(houses[1].availability)}
                transform="scale(-1)"
              />
              <text
                x={width >= 1025 ? '808.213' : '802'}
                y={width >= 1025 ? '590.213' : '596'}
                fontFamily="Roboto"
                fontSize={fontSize}
                style={{ lineHeight: '1' }}
              >
                <tspan
                  x={width >= 1025 ? '808.213' : '802'}
                  y={width >= 1025 ? '590.213' : '596'}
                  fill="#fff"
                  fontFamily="Roboto"
                  fontWeight="bold"
                >
                  {houses[1].houseNumber}
                </tspan>
              </text>
            </g>
          </>
        )}

        {shouldDisplay(houses[2].availability) && (
          <>
            <StyledPath
              d="M1661.834 507.952l9.638-143.305 4.716-.718v-3.897l-3.539-1.08-81.561-113.183-160.351 35.758-40.606-11.495-11.74-10.516-8.891-2.51-65.405 75.675-.172 79.997 24.614 36.004 13.402 21.936 6.301 1.391.112 4.77-4.1 1.238-2.697-.065.992 42.85L1471.154 578l63.709-22.72 11.368 7.558 25.977-9.84-.13-5.809-5.164-2.09 27.09-11.221 19.087 9.533 56.317-24.722-17.694-7.106z"
              {...getPathProps(2)}
            />
            <g transform="translate(791.18 -251.93)">
              <circle
                cx="-805.38"
                cy="-558.03"
                r={circleSize}
                className={getClassName(houses[2].availability)}
                transform="scale(-1)"
              />
              <text
                x={width >= 1025 ? '800.213' : '796'}
                y={width >= 1025 ? '565.646' : '571'}
                fontFamily="Roboto"
                fontSize={fontSize}
                style={{ lineHeight: '1' }}
              >
                <tspan
                  x={width >= 1025 ? '800.213' : '796'}
                  y={width >= 1025 ? '565.646' : '571'}
                  fill="#fff"
                  fontFamily="Roboto"
                  fontWeight="bold"
                >
                  {houses[2].houseNumber}
                </tspan>
              </text>
            </g>
          </>
        )}

        {shouldDisplay(houses[3].availability) && (
          <>
            <StyledPath
              d="M1667.5 422.58l106.94 37.681 50.475-18.245 12.159 5.285 20.572-7.27v-5.245l-5.82-1.7 19.355-6.078 15.611 5.504 48.287-20.091-17.524-4.975 9.222-5.152 15.155-125 2.685-6.662 3.856-.994-.146-3.78-5.095-1.124-77.59-100.59-138.2 29.782-44.296-9.978-7.065-7.594-10.972-1.837-56.404 68.31.302 28.674 63.601 87.455 3.539 1.08v3.897l-4.716.718z"
              {...getPathProps(3)}
            />
            <g transform="translate(1065.6 -340.55)">
              <circle
                cx="-813.38"
                cy="-572.03"
                r={circleSize}
                className={getClassName(houses[3].availability)}
                transform="scale(-1)"
              />
              <text
                x={width >= 1025 ? '808.213' : '802'}
                y={width >= 1025 ? '579.646' : '585'}
                fontFamily="Roboto"
                fontSize={fontSize}
                style={{ lineHeight: '1' }}
              >
                <tspan
                  x={width >= 1025 ? '808.213' : '802'}
                  y={width >= 1025 ? '579.646' : '585'}
                  fill="#fff"
                  fontFamily="Roboto"
                  fontWeight="bold"
                >
                  {houses[3].houseNumber}
                </tspan>
              </text>
            </g>
          </>
        )}

        {shouldDisplay(houses[4].availability) && (
          <>
            <StyledPath
              d="M1865.679 164.14l45.485-58.255 9.745 1.022 5.37 7.041 46.361 8.326 120.54-25.653 76.903 92.114 2.162.82-.287 3.812-4.413-.002-21.35 127.266 10.1 3.571-38.685 15.875-13.464-4.725-18.22 5.688 6.644 1.502-.364 4.974-17.5 7.026-11.067-4.916-43.507 11.953-85.374-22.715 7.21-61.574 2.686-6.662 3.856-.994-.146-3.78-5.096-1.125-77.589-100.59"
              {...getPathProps(4)}
            />

            <g transform="translate(1299.7 -408.52)">
              <circle
                cx="-805.38"
                cy="-560.03"
                r={circleSize}
                className={getClassName(houses[4].availability)}
                transform="scale(-1)"
              />
              <text
                x={width >= 1025 ? '800.213' : '794'}
                y={width >= 1025 ? '567.646' : '573'}
                fontFamily="Roboto"
                fontSize={fontSize}
                style={{ lineHeight: '1' }}
              >
                <tspan
                  x={width >= 1025 ? '800.213' : '794'}
                  y={width >= 1025 ? '567.646' : '573'}
                  fill="#fff"
                  fontFamily="Roboto"
                  fontWeight="bold"
                >
                  {houses[4].houseNumber}
                </tspan>
              </text>
            </g>
          </>
        )}

        {shouldDisplay(houses[5].availability) && (
          <>
            <StyledPath
              d="M2265.063 266.861l-36.369 11.966-71.419-19.954 10.27-65.508 4.413.002.287-3.811-2.162-.821-76.903-92.114-12.5 2.794 41.016-53.02 10.596.507 4.809 7.08 43.709 5.413 109.02-21.153 33.2 38.328 5.638.074 7.945 16.412 26.78 30.013-3.794 1.606-24.269 120.975 8.45 2.929-30.332 13.013-14.022-5.644-13.232 3.7 5.893 1.69-.054 5.112-14.619 6.674z"
              {...getPathProps(5)}
            />

            <g transform="translate(1484.6 -479.07)">
              <circle
                cx="-800.38"
                cy="-555.03"
                r={circleSize}
                className={getClassName(houses[5].availability)}
                transform="scale(-1)"
              />
              <text
                x={width >= 1025 ? '794.213' : '788'}
                y={width >= 1025 ? '561.646' : '567'}
                fontFamily="sans-serif"
                fontSize={fontSize}
                style={{ lineHeight: '1' }}
              >
                <tspan
                  x={width >= 1025 ? '794.213' : '788'}
                  y={width >= 1025 ? '561.646' : '567'}
                  fill="#fff"
                  fontFamily="Roboto"
                  fontWeight="bold"
                >
                  {houses[5].houseNumber}
                </tspan>
              </text>
            </g>
          </>
        )}
      </svg>
    </StyledWrapper>
  );
};

interface Props {
  className?: string;
  isOnlyAvailable: boolean;
}

export default MappedVector;
