import React, { FC } from 'react';
import styled from 'styled-components';
import Headline from '@components/atoms/Headline/Headline';
import Map from '@components/molecules/Map/Map';
import ContactBlock from '@components/molecules/ContactBlock/ContactBlock';
import { graphql, useStaticQuery } from 'gatsby';
import NewMap from '../../molecules/NewMap/NewMap';

const StyledWrapper = styled.section`
  width: 90%;
  max-width: 620px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 45px;

  @media (min-width: 470px) {
    margin-top: 85px;
  }

  @media (min-width: 1025px) {
    margin-top: 180px;
    max-width: unset;
  }

  @media (min-width: 1225px) {
    width: 100%;
    padding-left: 5%;
  }

  @media print {
    padding-left: 0;
  }
`;

const StyledInnerWrapper = styled.div`
  @media (min-width: 1225px) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    max-width: 1700px;
    margin-left: auto;
  }

  @media (min-width: 1920px) {
    margin: auto;
  }

  @media print {
    width: 100%;
    max-width: unset;
    margin-right: auto;
    display: block;
    break-inside: avoid;
  }
`;

const StyledColumn = styled.div`
  &:first-child {
    width: 100%;

    @media (min-width: 1025px) {
      width: 54vw;
    }
  }
  @media print {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const StyledHeadline = styled(Headline)`
  margin-bottom: 45px;

  @media (min-width: 520px) {
    margin-bottom: 80px;
  }

  @media (min-width: 1025px) {
    margin-bottom: 160px;
  }

  @media print {
    display: none;
  }
`;

const StyledMap = styled(NewMap)`
  margin: 25px 0 85px;

  @media (min-width: 1225px) {
    margin: 0;
    width: 54vw;
    height: 54vw;
    max-width: 1054px;
    max-height: 1054px;
  }

  @media print {
    display: none;
  }
`;

const StyledContactBlockLink = styled.a`
  color: inherit;
  text-decoration: none;
`;

const StyledContactBlock = styled(ContactBlock)`
  margin-bottom: 30px;

  @media (min-width: 1025px) {
    :last-of-type {
      margin-bottom: 0;
    }
  }
`;

const Contact: FC = (props) => {
  const {
    datoCmsContact: { eMail, location, phoneNumber },
  } = useStaticQuery(query);

  return (
    <StyledWrapper {...props}>
      <StyledHeadline
        behindText="Kontakt"
        behindTextSize="9rem"
        id="contact"
        secondaryText="Kontakt z nami"
      >
        Masz jakieś pytania? Skontaktuj się z nami!
      </StyledHeadline>

      <StyledInnerWrapper>
        <StyledColumn>
          <StyledMap data-aos="fade-up" />
        </StyledColumn>

        <StyledColumn>
          <StyledContactBlock
            icon="location"
            headline="Lokalizacja"
            data-aos="zoom-out"
          >
            {location}
          </StyledContactBlock>

          <StyledContactBlock
            icon="email"
            headline="Adres e-mail"
            data-aos="zoom-out"
          >
            <StyledContactBlockLink href={`mailto:${eMail}`}>
              {eMail}
            </StyledContactBlockLink>
          </StyledContactBlock>

          <StyledContactBlock
            icon="phone"
            headline="Telefon"
            data-aos="zoom-out"
          >
            <StyledContactBlockLink href={`tel:${phoneNumber}`}>
              {phoneNumber}
            </StyledContactBlockLink>
          </StyledContactBlock>
        </StyledColumn>
      </StyledInnerWrapper>
    </StyledWrapper>
  );
};

const query = graphql`
  {
    datoCmsContact {
      eMail
      phoneNumber
      location
    }
  }
`;

export default Contact;
