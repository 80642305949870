import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import MappedVector from './MappedVector/MappedVector';
import Legend from '@components/molecules/InteractiveVisualization/Legend/Legend';
import Checkbox from '@components/atoms/Checkbox/Checkbox';
import Headline from '@components/atoms/Headline/Headline';

const StyledWrapper = styled.div`
  @media (min-width: 1025px) {
    width: 90%;
    max-width: 1640px;
    margin: auto;
  }

  @media print {
    display: none;
  }
`;

const StyledVisWrapper = styled.div`
  position: relative;
`;

const StyledMappedVector = styled(MappedVector)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const StyledBottomWrapper = styled.div`
  width: 90%;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;

  @media (min-width: 1025px) {
    width: 100%;
  }
`;

const StyledLegendsWrapper = styled.div`
  @media (min-width: 1025px) {
    display: flex;
  }
`;

const StyledLegend = styled(Legend)`
  @media (min-width: 1025px) {
    margin-right: 30px;
  }
`;

const InteractiveVisualization: FC<Props> = ({ className, ...props }) => {
  const image = getImage(useStaticQuery(query).file);
  const [isOnlyAvailable, setIsOnlyAvailable] = useState<boolean>(false);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsOnlyAvailable(e.currentTarget.checked);
  };

  return (
    <StyledWrapper className={className} {...props}>
      <StyledVisWrapper>
        <GatsbyImage alt="Diamentowe Zacisze" image={image} />
        <StyledMappedVector isOnlyAvailable={isOnlyAvailable} />
      </StyledVisWrapper>

      <StyledBottomWrapper>
        <StyledLegendsWrapper>
          <StyledLegend color="available">Wolne</StyledLegend>
          <StyledLegend color="reserved">Zarezerwowane</StyledLegend>
          <StyledLegend color="unavailable">Sprzedane</StyledLegend>
        </StyledLegendsWrapper>
        <div>
          <Checkbox checked={isOnlyAvailable} onChange={handleCheckboxChange}>
            Pokaż tylko dostępne
          </Checkbox>
        </div>
      </StyledBottomWrapper>
    </StyledWrapper>
  );
};

interface Props {
  className?: string;
}

const query = graphql`
  {
    file(name: { eq: "interactiveVisImg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          placeholder: BLURRED
          formats: [WEBP, JPG]
          layout: FULL_WIDTH
        )
      }
    }
  }
`;

export default InteractiveVisualization;
